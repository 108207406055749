import React from 'react';
import axios from "axios";
import { API_SERVER } from '../../constant';
import { Auth } from "aws-amplify";
import { history } from '../history/history';


//let authToken = localStorage.getItem("CognitoIdentityServiceProvider.3r1b345riifgtn5a6ius8ovt04.b4792b7b-3736-4955-a181-f716fd590b5d.idToken");


export const getIdToken = async () => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    //console.log('idToken:', idToken);
    return idToken;
}

export const getLoggedInUserEmail = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log('User Data:', user, user.username);
    const userInfo = { username: user.username, email: user.attributes.email}
    return userInfo;
    // const emailAttribute = userData.UserAttributes.find(attr => attr.Name === 'email');
    // if (emailAttribute) {
    //   email = emailAttribute.Value;
    //   return email;
    // } else {
    //   console.log("Email attribute not found.");
    //   return "Email attribute not found.";
    // }
}
export const getUserOutputFileData = async (val) => {

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    try {
        let s3OutputFilePath = localStorage.getItem("outputfilepath");
        let bucket = localStorage.getItem("bucket");
        let replaceName = bucket + '/';
        let newKeyPath = s3OutputFilePath.replace(replaceName, '');
        const response = await axios.get(API_SERVER + `s3-get-user-outputfile-data`, {
            params: {
                getJson: val,
                bucket: bucket,
                key: newKeyPath,
            },
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });
        console.log("getUseroutputfileData", response.data);
        if (val === "lotstotal") {
            let lotList = response.data.jsonData.LotList;
            const totalLOTQuantity = lotList.reduce((total, lot) => total + lot.LOT_quantity, 0);
            console.log("Total LOT_quantity", totalLOTQuantity);
            return totalLOTQuantity;
        }
    } catch (e) {
        console.log("Error", e);
    }

};

export const getUserFileUploadToken = async () => {

    const idToken = await getIdToken();
    const userInfo = await getLoggedInUserEmail();
    console.log('idToken:', idToken);
    console.log('User Data:', userInfo.email);
    console.log('User Data:', userInfo.username);

    //return true;
    try {
        const response = await axios.post(API_SERVER + `getUserFileUploadToken`, {
            'authToken': idToken,
            'email': userInfo.email,
        }, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        })
        console.log("totalquantityremaining", response.data);
        let totalTokenQuantity = response.data.totalquantityremaining;
        let lotsList = response.data.LotList;
        //setProduct(response.data);
        return {
            totalTokenQuantity,
            lotsList,
        }

    } catch (e) {
        console.log("Error", e);
    }
}
export const checkUserSession = async () => {
    try {
        const session = await Auth.currentSession();
        // Session is valid, you can check for specific tokens if needed
        console.log('Session:', session);
    } catch (err) {
        // If there's an error, the session might be expired or the user is not authenticated
        console.log('Session expired or user not authenticated:', err);
        //navigate('/'); // Redirect to home page
        history.push('/');
    }
};
